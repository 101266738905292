/* TODO - finish converting the styling to MDBreact to remove this file... Almost finished then remove most of the css */

html {
    height: 100%;
    width: 100%;
}

body {
    font-family: 'Merriweather', 'Helvetica Neue', Arial, sans-serif;
    /* background-color: #212529 !important; */
    background-color: #212121 !important; /* Note: also known as elegant-color-dark */
}

hr {
    max-width: 50px;
    border-width: 3px;
    border-color: #f05f40;
}

hr.light {
    border-color: #fff;
}

/* .bg-dark {
    background-color: #212529 !important;
} */

.minor-space {
    padding: 3rem 0 !important;
}

.text-faded {
    color: rgba(255, 255, 255, 0.7);
}

section {
    padding: 3rem 0;
}

.section-heading {
    margin-top: 0;
}

::-moz-selection {
    color: #fff;
    background: #212529;
    text-shadow: none;
}

::selection {
    color: #fff;
    background: #212529;
    text-shadow: none;
}

img::selection {
    color: #fff;
    background: transparent;
}

img::-moz-selection {
    color: #fff;
    background: transparent;
}

.service-box {
    max-width: 400px;
}

/* Portfolio images for each construction site */
.portfolio-box {
    position: relative;
    display: block;
    max-width: 550px;
    margin: 0 auto;
}

.portfolio-box .portfolio-box-caption {
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
    opacity: 0;
    color: #fff;
    background: rgba(240, 96, 64, 0.5);
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
}

.portfolio-box .portfolio-box-caption .portfolio-box-caption-content {
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    text-align: center;
}

.portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-category,
.portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-name {
    padding: 0 15px;
    font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
}

.portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-category {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
}

.portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-name {
    font-size: 18px;
}

.portfolio-box:hover .portfolio-box-caption {
    opacity: 1;
}

.portfolio-box:focus {
    outline: none;
}

@media (min-width: 768px) {
    .portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-category {
        font-size: 16px;
    }
    .portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-name {
        font-size: 22px;
    }
}
