.image-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 1px 7px;
    grid-auto-rows: 10px;
    border: white;
}

.image-list img {
    width: 250px;
}

#details button.btn-default {
    font-size: 0.7rem;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.7);
    background-color: #f05f40 !important;
}

/* TODO - return to this, currently fixed temporary, but will need to enhance the component styling */
#details button.btn-default:hover {
    color: #fff;
    background-color: #ee4b28 !important;
}

#details {
    font-family: 'Exo 2', sans-serif;
    margin-top: auto;

    /* -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s; */
}

#details .row,
#details h2 {
    color: rgba(255, 255, 255, 0.7);
}
