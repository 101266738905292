#contactForm {
    font-family: 'Exo 2', sans-serif;
}

#contactForm .md-form > input[type='date']:focus:not([readonly]),
#contactForm .md-form > input[type='datetime-local']:focus:not([readonly]),
#contactForm .md-form > input[type='email']:focus:not([readonly]),
#contactForm .md-form > input[type='number']:focus:not([readonly]),
#contactForm .md-form > input[type='password']:focus:not([readonly]),
#contactForm .md-form > input[type='search-md']:focus:not([readonly]),
#contactForm .md-form > input[type='search']:focus:not([readonly]),
#contactForm .md-form > input[type='tel']:focus:not([readonly]),
#contactForm .md-form > input[type='text']:focus:not([readonly]),
#contactForm .md-form > input[type='time']:focus:not([readonly]),
#contactForm .md-form > input[type='url']:focus:not([readonly]),
#contactForm .md-form > textarea.md-textarea:focus:not([readonly]) {
    color: rgba(255, 255, 255, 0.7);
    border-bottom: 1px solid rgba(255, 255, 255, 0.7);
    box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.7);
}

#contactForm .md-form > .prefix.active {
    color: #f05f40 !important;
}

#contactForm .md-form > label.active {
    color: #f05f40 !important;
}

#contactForm .form-control {
    color: rgba(255, 255, 255, 0.7);
}

#contactForm .row {
    color: rgba(255, 255, 255, 0.7);
}

#contactForm button.btn-default {
    font-size: 0.7rem;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.7);
    background-color: #f05f40 !important;
}

#contactForm button.btn-default:hover {
    color: #fff;
    background-color: #ee4b28 !important;
}

/* #ea4321 */
