#mainFooter {
    font-family: 'Exo 2', sans-serif;
    margin-top: auto;

    /* -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s; */
}

#mainFooter .row {
    color: rgba(255, 255, 255, 0.7);
}

#mainFooter .row > .md-center > button.btn-default,
#mainFooter .row > .md-center > a.btn-default {
    font-size: 0.7rem;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.7);
    background-color: #f05f40 !important;
}

#mainFooter .row > .md-center > button.btn-default:hover,
#mainFooter .row > .md-center > a.btn-default:hover {
    color: #fff;
    background-color: #ee4b28 !important;
}

@media (min-width: 768px) {
    #minimizedfooter {
        position: absolute;
        bottom: 0;
        width: 100%;
    }
}
